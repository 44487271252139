<template>
  <div class="container">
    <header>
      <img
        src="@/assets/newImg/arrow_left.png"
        width="18px"
        height="15px"
        @click="$router.go(-1)"
      />
    </header>
    <!-- 标题 -->
    <div class="title">
      <div class="title_Btn"> {{ $t('user').ChangePassword.toUpperCase() }}</div>
    </div>
    <!-- 表单 -->
    <div class="content">
      <div class="form">
        <!-- 旧密码 -->
        <div class="item">
          <div class="item_lable"> {{ $t('user').OldPassword }}</div>
          <van-field v-model="oldPassword" type="password" placeholder="">
          </van-field>
        </div>
        <!-- 新密码 -->
        <div class="item">
          <div class="item_lable"> {{ $t('user').NewPassword }}</div>
          <van-field v-model="newPassword" type="password" placeholder="">
          </van-field>
        </div>
        <!-- 确认 -->
        <div class="item_btn" @click="handleSub"> {{ $t('user').confirmChanges.toUpperCase() }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      oldPassword: '',
      newPassword: ''
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {},
  methods: {
    //提交
    async handleSub() {
      if (!this.oldPassword) return
      if (this.newPassword.length < 6) {
        this.$toast(this.$t('common').passwordCheck)
        return false
      }
      const form = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      }
      await userApi.editPassword(form)
      this.$toast(this.$t('common').success)
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background: #ffffff;
  padding-bottom: 30px;
  header {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    height: 291px;
    background-image: url(../../assets/newImg/regedit_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px 20px 0 20px;
    .header_right {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      right: 20px;
      .txt {
        margin: 0 10px;
      }
      .img {
        margin-left: 3px;
        margin-bottom: 2px;
      }
    }
  }
  .title {
    margin-top: -270px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title_Btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 212px;
      height: 40px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      box-shadow: 0px 1px 3px 0px rgba(173, 61, 0, 0.75);
      border-radius: 20px;
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 18px;
      color: #ffffff;
      line-height: 12px;
    }
  }
  .content {
    margin: -20px 20px 0 20px;
    .form {
      background: #ffffff;
      box-shadow: 0px 2px 12px 0px rgba(73, 47, 182, 0.75);
      border-radius: 15px;
      padding: 40px 20px;
      .item_lable {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 12px;
        color: #333333;
        margin-bottom: 10px;
      }
      .item_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        height: 44px;
        background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
        border-radius: 22px;
        font-family: PingFang SC;
        font-weight: 800;
        font-size: 18px;
        color: #ffffff;
      }
      .select_btn {
        margin-right: 10px;
      }
      .select_country_btn {
        height: 38px;
        border-radius: 19px;
        border: 1px solid #999999;
        padding: 0 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      .send_btn {
        margin-right: -13px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 24px;
        background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
        border-radius: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 12px;
        color: #ffffff;
      }
      .van-button {
        height: 37px;
        margin-bottom: 10px;
        margin-top: 20px;
      }
    }
  }
  ::v-deep .van-field__value {
    overflow: visible;
    display: grid;
  }
  ::v-deep .van-button__content {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
  }
  .van-cell {
    margin-bottom: 20px;
    height: 38px;
    border-radius: 19px;
    border: 0.5px solid #999999;
    display: flex;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #333333;
  }
  ::v-deep .van-field__right-icon {
    margin: -10px 8px;
    padding: 0px;
    line-height: normal;
  }
}
</style>
